@font-face {
    font-family: 'SmartFontUI';
    src: url(./fonts/SmartFontUI.otf);
}

@font-face {
    font-family: 'CascadiaCode-Bold';
    src: url(./fonts/CascadiaCode-Bold.otf);
}

@font-face {
    font-family: 'CascadiaCode-Light';
    src: url(./fonts/CascadiaCode-Light.otf);
}

@font-face {
    font-family: 'ZEROxProto';
    src: url(./fonts/ZEROxProto.otf);
}

body {
    margin: 0;
    font-family: 'SmartFontUI', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}